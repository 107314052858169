import React from "react";
import { Col, Row, Typography } from "antd";
import { ProductCategoryOutput, ProductOutput } from "../../graphql/schema";
import ProductCardContainer from "./ProductCardContainer";

interface Props {
  category: Partial<ProductCategoryOutput> | null;
  products: Partial<ProductOutput>[];
  searchValue: string;
}

const ProductList = ({ category, products, searchValue }: Props) => {
  // Filter products based on the search value
  const filteredProducts = products?.filter(
    (p) =>
      p.name &&
      (p.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        !searchValue),
  );

  const hasProducts = filteredProducts && filteredProducts.length > 0;

  return (
    <div>
      {hasProducts && (
        <>
          <Row>
            <Col span={24}>
              <Typography.Text
                strong
                style={{
                  marginTop: 10,
                  fontWeight: "bold",
                }}
              >
                {category?.name}
              </Typography.Text>
            </Col>
          </Row>
          <Row justify="space-between">
            {filteredProducts
              .sort((a, b) => (a?.index || 0) - (b?.index || 0))
              .map((product) => (
                <Col key={product.id} xs={24} sm={24} md={12} lg={12} xl={12}>
                  <ProductCardContainer
                    searchValue={searchValue}
                    product={product}
                    products={products}
                  />
                </Col>
              ))}
          </Row>
        </>
      )}
    </div>
  );
};

export default ProductList;
