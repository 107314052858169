import React, { useCallback, useMemo } from "react";
import { Col, Row, Typography } from "antd";
import dayjs from "dayjs";
import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";

interface Props {
  startHour: number;
  endHour: number;
  timeOfDay: string;
  dates: dayjs.Dayjs[];
  selectedDate: Date | undefined;
}

const SlotsContainer = ({
  dates,
  selectedDate,
  timeOfDay,
  endHour,
  startHour,
}: Props) => {
  const { stateValues, setStates } = useBooking();

  const slots = useMemo(() => {
    if (!selectedDate || !dates.length) return [];

    const selectedDay = dayjs(selectedDate).tz();
    const start = selectedDay.hour(startHour).minute(0).second(0);
    const end = selectedDay.hour(endHour).minute(0).second(0);

    return dates.filter((date) => {
      const dateInTZ = date.tz();
      return dateInTZ.isSameOrAfter(start) && dateInTZ.isBefore(end);
    });
  }, [dates, selectedDate, startHour, endHour]);

  const handleClick = useCallback(
    (date: dayjs.Dayjs) => {
      if (!stateValues) return;

      const isSameAppointment =
        stateValues.appointment &&
        dayjs(stateValues.appointment).tz().isSame(date.tz());

      setStates({
        ...stateValues,
        appointment: isSameAppointment ? undefined : date.tz(),
      });
    },
    [stateValues, setStates],
  );

  if (!slots.length)
    return (
      <Row
        align="middle"
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Typography.Text>Ausgebucht</Typography.Text>
        </Col>
      </Row>
    );

  return (
    <>
      {slots.map((date, index) => {
        const isSelected =
          stateValues?.appointment &&
          dayjs(stateValues.appointment).tz().isSame(date.tz());

        return (
          <Row
            key={`TimeSlot_${timeOfDay}_${index}`}
            align="middle"
            justify="center"
            onClick={() => handleClick(date)}
          >
            <Col
              span={20}
              style={{
                marginTop: "10px",
                cursor: "pointer",
                paddingBottom: 5,
                paddingTop: 5,
                paddingRight: 20,
                paddingLeft: 20,
                width: 81,
                backgroundColor: isSelected ? "#8B8B8B" : "#F6F6F4",
                borderRadius: "15px",
              }}
            >
              <Typography.Text
                strong
                style={{
                  color: isSelected
                    ? theme.colors.background
                    : theme.colors.text,
                }}
              >
                {date.tz().format("HH:mm")}
              </Typography.Text>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default React.memo(SlotsContainer);
