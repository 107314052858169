import React, { useCallback, useMemo } from "react";
import { Calendar, Card, Col, Row, Spin, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { addMonths, isSameDay, isWithinInterval, startOfMonth } from "date-fns";
import styles from "./Calendar.module.scss";
import { useLayout } from "../layout/LayoutContext";
import EarlierAppointment from "./EarlierAppointment";
import theme from "../../theme/theme";

interface Props {
  onSelectDate: (date: Date | undefined) => void;
  dates: Dayjs[];
  onChangeDates: (start: Date, end: Date) => void;
  vacanciesSearchDate: {
    start: Date;
    end: Date;
  };
  setVacanciesSearchDate: (date: Date) => void;
  notifyPlacement: "top" | "bottom";
  selectedDate: Date | undefined;
  loading: boolean;
}

const slotsOutdatedStyle = {
  color: "black",
  width: "100%",
  backgroundColor: "#F8F8F8",
  borderStyle: "solid",
  height: "100%",
  borderColor: "#D5D4DF",
  borderWidth: 0.5,
  padding: "0px",
  alignItems: "center",
  justifyContent: "center",
};

const slotsSelectedStyle = {
  color: "black",
  width: "100%",
  backgroundColor: "#8B8B8B",
  borderStyle: "solid",
  height: "100%",
  borderColor: "#D5D4DF",
  borderWidth: 0.5,
  padding: "0px",
  alignItems: "center",
  justifyContent: "center",
};

const slotsAvailableStyle = {
  color: theme.colors.background,
  width: "100%",
  backgroundColor: "#B5DE82",
  borderStyle: "solid",
  height: "100%",
  borderColor: "#D5D4DF",
  borderWidth: 0.5,
  padding: "0px",
  alignItems: "center",
  justifyContent: "center",
};

const slotsDefaultStyle = {
  color: "black",
  width: "100%",
  backgroundColor: "#fff",
  borderStyle: "solid",
  height: "100%",
  borderColor: "#D5D4DF",
  borderWidth: 0.5,
  padding: "0px",
  alignItems: "center",
  justifyItems: "center",
};

const formatDate = (d: Dayjs) => d.format("YYYY-MM-DD");

const CalendarCard = React.memo(
  ({
    onSelectDate,
    dates,
    selectedDate,
    onChangeDates,
    vacanciesSearchDate,
    notifyPlacement,
    loading,
  }: Props) => {
    const { responsive } = useLayout();

    // Memoisierung des dateSet
    const dateSet = useMemo(() => {
      return new Set(dates.map(formatDate));
    }, [dates]);

    const handleSelect = useCallback(
      (value: Dayjs) => {
        const dateStr = value.format("YYYY-MM-DD");
        const selectedDay = value.set("hours", 12).toDate();

        if (dateSet.has(dateStr)) {
          onSelectDate(selectedDay);
        } else if (
          !isWithinInterval(value.toDate(), {
            start: vacanciesSearchDate.start,
            end: vacanciesSearchDate.end,
          })
        ) {
          const newStart = startOfMonth(value.toDate());
          const newEnd = addMonths(newStart, 1);
          onChangeDates(newStart, newEnd);
          onSelectDate(selectedDay);
        } else {
          onSelectDate(undefined);
        }
      },
      [dateSet, onSelectDate, onChangeDates, vacanciesSearchDate],
    );

    const fullCellRender = useCallback(
      (value: Dayjs) => {
        const dateStr = value.format("YYYY-MM-DD");
        const day = value.date();

        const padding = responsive({
          desktop: "0px",
          tablet: "20px",
          mobile: "0px",
        });

        const cellStyle = () => {
          if (
            value.toDate() < new Date() &&
            !isSameDay(value.toDate(), new Date())
          ) {
            return slotsOutdatedStyle;
          }
          if (selectedDate && isSameDay(value.toDate(), selectedDate)) {
            return slotsSelectedStyle;
          }
          if (dateSet.has(dateStr)) {
            return slotsAvailableStyle;
          }
          return slotsDefaultStyle;
        };

        const textStyle = () => {
          if (
            value.toDate() < new Date() &&
            !isSameDay(value.toDate(), new Date())
          ) {
            return { color: "#A9A5A5", fontWeight: 300 };
          }
          if (selectedDate && isSameDay(value.toDate(), selectedDate)) {
            return { color: "white", fontWeight: 300 };
          }
          if (dateSet.has(dateStr)) {
            return { color: "white", fontWeight: 300 };
          }
          return { fontWeight: 300 };
        };

        return (
          <Row
            style={{
              ...cellStyle(),
              padding,
            }}
          >
            <Col span={24}>
              <Typography.Text style={textStyle()}>{day}</Typography.Text>
            </Col>
          </Row>
        );
      },
      [responsive, selectedDate, dateSet],
    );

    const headerRender = useCallback(
      ({ value, onChange }) => {
        const current = value.clone().toDate();

        const handlePrev = () => {
          const newStart = addMonths(startOfMonth(current), -1);
          const newEnd = startOfMonth(current);
          onChangeDates(newStart, newEnd);
          onChange(dayjs(addMonths(current, -1)));
        };

        const handleNext = () => {
          const newStart = addMonths(startOfMonth(current), 1);
          const newEnd = addMonths(startOfMonth(current), 2);
          onChangeDates(newStart, newEnd);
          onChange(dayjs(addMonths(current, 1)));
        };

        return (
          <Row justify="start">
            <Col>
              <Row justify="start" align="middle">
                <Col>
                  <LeftOutlined style={{ padding: 5 }} onClick={handlePrev} />
                  <Typography.Text strong style={{ padding: 5, fontSize: 20 }}>
                    {value.format("MMM YYYY")}
                  </Typography.Text>
                  <RightOutlined style={{ padding: 5 }} onClick={handleNext} />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      },
      [onChangeDates],
    );

    return (
      <div>
        {notifyPlacement === "top" && <EarlierAppointment />}
        <Card
          bodyStyle={{ padding: 0 }}
          style={{
            border: "1px solid #A9A5A5",
            minHeight: 300,
            padding: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Spin />
          ) : (
            <Calendar
              value={dayjs(vacanciesSearchDate.start)}
              fullscreen={false}
              className={styles.antTableThead}
              onSelect={handleSelect}
              fullCellRender={fullCellRender}
              headerRender={headerRender}
            />
          )}
        </Card>
        {notifyPlacement === "bottom" && <EarlierAppointment />}
      </div>
    );
  },
);

export default CalendarCard;
