import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Spin } from "antd";
import { addMonths, startOfMonth } from "date-fns";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useLayout } from "../layout/LayoutContext";
import useBooking from "../bookingProvider/useBooking";
import {
  useGetPublicStoreQuery,
  useVacanciesSearchQuery,
} from "../../graphql/schema";
import SubHeader from "../base/SubHeader";
import SlotCard from "./SlotCard";
import CalendarMobile from "./CalendarMobile";
import CalendarCard from "./CalendarCard";

const CalendarContainer = () => {
  const { responsive } = useLayout();
  const { stateValues, setStates } = useBooking();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date(),
  );
  const [vacanciesSearchDate, setVacanciesSearchDate] = React.useState<any>({
    start: startOfMonth(new Date()),
    end: addMonths(startOfMonth(new Date()), 1),
  });
  const { data, refetch, loading } = useVacanciesSearchQuery({
    variables: {
      dto: {
        start: vacanciesSearchDate.start,
        end: vacanciesSearchDate.end,
        products: stateValues.bookedProducts.map((product) => ({
          productId: product.id,
          variationId: product.variation,
        })),
        store: stateValues.store.id!,
      },
    },
    fetchPolicy: "no-cache",
  });
  const { data: store } = useGetPublicStoreQuery({
    variables: {
      id: stateValues.store.id!,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (stateValues && !stateValues.bookedProducts.length) {
      navigate("/services");
    }
  }, []);

  useEffect(() => {
    if (stateValues && !stateValues.bookedProducts.length) {
      navigate("/services");
    }
  }, [stateValues]);

  const handleChangeDates = async (start: Date, end: Date) => {
    setVacanciesSearchDate({
      start,
      end,
    });
    await refetch({
      dto: {
        start,
        end,
        products: stateValues.bookedProducts.map((product) => ({
          productId: product.id,
          variationId: product.variation,
        })),
        store: stateValues.store.id!,
      },
    });
  };

  const dates = useMemo(
    () =>
      data?.vacanciesSearch?.slots?.map((timestamp: number) => {
        return dayjs.tz(timestamp);
      }) || [],
    [data],
  );
  return (
    <div>
      <Row justify="center">
        <Col span={24}>
          <SubHeader
            title="Wähle einen Termin aus"
            onBack={() => {
              setStates({
                ...stateValues,
                appointment: null,
              });
              navigate("/services");
            }}
          />
        </Col>
      </Row>
      <Row
        justify={responsive({
          desktop: "center",
          tablet: "start",
          mobile: "center",
        })}
        gutter={24}
      >
        <Col
          span={responsive({
            desktop: 12,
            tablet: 18,
            mobile: 24,
          })}
        >
          {loading ? (
            <Spin />
          ) : (
            responsive({
              desktop: (
                <CalendarCard
                  notifyPlacement="bottom"
                  onSelectDate={setSelectedDate}
                  dates={dates}
                  onChangeDates={handleChangeDates}
                  selectedDate={selectedDate}
                  vacanciesSearchDate={vacanciesSearchDate}
                  setVacanciesSearchDate={setVacanciesSearchDate}
                />
              ),
              tablet: (
                <CalendarCard
                  notifyPlacement="top"
                  onSelectDate={setSelectedDate}
                  dates={dates}
                  onChangeDates={handleChangeDates}
                  selectedDate={selectedDate}
                  vacanciesSearchDate={vacanciesSearchDate}
                  setVacanciesSearchDate={setVacanciesSearchDate}
                />
              ),
              mobile: (
                <CalendarMobile
                  dates={dates}
                  selectedDate={selectedDate}
                  onChangeDates={handleChangeDates}
                  setSelectedDate={setSelectedDate}
                  vacanciesSearchDate={vacanciesSearchDate}
                  setVacanciesSearchDate={setVacanciesSearchDate}
                />
              ),
            })
          )}
        </Col>
        <Col
          span={responsive({
            desktop: 12,
            tablet: 18,
            mobile: 24,
          })}
        >
          <SlotCard dates={dates} selectedDate={selectedDate} />
        </Col>
      </Row>
    </div>
  );
};

export default CalendarContainer;
