import Card from "./ProductCard";
import { ProductOutput } from "../../graphql/schema";

interface Props {
  product: Partial<ProductOutput>;
  products: Partial<ProductOutput>[];
  searchValue?: string;
}

const ProductCardContainer = ({ product, searchValue, products }: Props) => {
  if (!product) return null;
  return !product?.variations || !product?.variations.length ? (
    <Card
      searchValue={searchValue}
      singleProduct={product}
      productId={product?.id}
      products={products}
      description={product.descriptionLong}
    />
  ) : (
    product.variations.map((variation: any) => (
      <Card
        key={`ProductCard_${product.id}_${variation.id}`}
        variationId={variation.id}
        productId={product?.id}
        products={products}
        searchValue={searchValue}
        description={product.descriptionLong}
        singleProduct={variation}
      />
    ))
  );
};

export default ProductCardContainer;
